











import Vue from "vue";

export default Vue.extend({
  components: {},
  props: {
    principle: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {};
  },
});
