

































































































































































































































































































































































































































































































import Vue from "vue";
import Testimonial from "@/components/Testimonial.vue";
import SignupButton from "@/components/SignupButton.vue";
import PreFooter from "@/components/PreFooter.vue";
import DesignPrinciple from "@/components/DesignPrinciple.vue";
import testimonials from "@/assets/data/testimonials.json";
import features from "@/assets/data/features.json";
import principles from "@/assets/data/principles.json";
import { CheckCircleIcon } from "vue-feather-icons";
import api from "@/api/api";

export default Vue.extend({
  components: {
    Testimonial,
    SignupButton,
    PreFooter,
    DesignPrinciple,
    CheckCircleIcon,
  },
  data() {
    return {
      url: "https://boristane.com",
      testimonials,
      features,
      principles,
      users: [] as string[],
      persona: "Boris",
      isUsageHidden: true,
    };
  },
  metaInfo(): { title: string } {
    return this.$route.meta;
  },
  methods: {
    rotatePersonas() {
      let i = 1;
      setInterval(() => {
        this.isUsageHidden = true;
        setTimeout(() => {
          this.isUsageHidden = false;
        }, 15000);
        setTimeout(() => {
          this.persona = this.users[i];
          i += 1;
          if (i >= this.users.length) {
            i = 0;
          }
        }, 800);
      }, 20000);
    },
  },
  async mounted() {
    this.users = await api.getRecentUsers();
    this.rotatePersonas();
  },
});
